import React, {  useContext } from "react";
import { ProjectContext } from "../context/ProjectsContext";
import ReactHtmlParser from "react-html-parser";

const About = () => {
  const { projects } = useContext(ProjectContext);
  
  return (
    <section className="about">
      <div className="container">
        {projects && projects.description && (<div>
          {ReactHtmlParser(projects.description)}
          </div>
          )}
      </div>
    </section>
  );
};

export default About;
