import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { Popup } from "../lib/magnificpopup/Popup";
import Player from "@vimeo/player";
import { isMobile } from "react-device-detect";
import Video from './Video';

let monsterData = [];

export const Portfolio = ({
  projects,
  parseProjectTitle,
  parseProjectDescription
}) => {
  monsterData = projects;
  return (
    <section className="p-0" id="portfolio">
      <div className="mfp-if-bg" id="iframe-video-background" />
      <div className="container-fluid">
        <div className="row no-gutter popup-gallery">
          {projects.map(function (project, i) {
            let imageUrl = project.thumbnail_16x19
              ? project.thumbnail_16x19
              : project.thumbnail_large;
            return (
              <Popup
                className="popup-vimeo"
                key={i}
                id={project.vimeoId}
                data-videoid={i}
                href={
                  "https://player.vimeo.com/video/" +
                  project.vimeoId +
                  "?playsinline=0&muted=1&autoplay=1" // Added controls=1
                }
                style={{ width: $(window).width() < 600 ? "100%" : "50%" }}
                config={popupConfig}
              >
                <div key={project.id} className="portfolio-box">
                  {isMobile === false && (
                    <img className="img-fluid" alt="thumbnail" src={imageUrl} />
                  )}

                  {isMobile && (
                    <Video
                      projectsTotal={projects.length - 1}
                      imageURL={{ imageUrl }}
                      id={"mobile-preview" + project.vimeoId}
                      src={"https://s3.eu-west-1.amazonaws.com/monstermusic.tv/video/394x400/" + project.vimeoId + ".mp4"}
                    />
                  )}

                  <div className="portfolio-box-caption">
                    <div className="portfolio-box-caption-content">
                      <div className="project-name project-title">
                        {parseProjectTitle(project.name)}
                      </div>
                    </div>
                    <div className="project-data">
                      {parseProjectDescription(project.name)}
                    </div>
                  </div>
                </div>
              </Popup>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const popupConfig = {
  type: "iframe",
  iframe: {
    markup:
      '<div class="mfp-iframe-scaler">' +
      '<div style="z-index:999" class="mfp-close">x</div>' +
      '<iframe class="mfp-iframe" frameborder="0" allow="autoplay" allowfullscreen>' +
      "</iframe>" +
      '<div class="mfp-title" style="pointer-events: none;">' +
      '<div class="d-none d-md-block mfp-title-custom"></div>' +
      '<p class="d-none d-md-block mfp-title-p"></p>' +
      "</div>" +
      "</div>"
  },
  callbacks: {
    change: function () {
      let iframe = $(this.content).find("iframe");
      let player = new Player(iframe[0], {
        id: 'monsterVideoInTheRain',
        autoplay: true,
        muted: true
      });

      let id = $(this.st.el)[0].dataset["videoid"];
      let creditsText = monsterData[id].description;
      creditsText = creditsText.split("\n").join("<br/>");
      let creditsContainer = $(this.content).find(".mfp-title");
      let closeButton = $(this.content).find(".mfp-close");
      let monsterCreditTextParagraph = $(this.content).find(".mfp-title-p");

      $("#iframe-video-background.mfp-if-bg").css("visibility", "visible");
      monsterCreditTextParagraph.html(creditsText);
      $(this.content).find(".mfp-title-custom").html(monsterData[id].title);

      // Close the popup when the video ends
      player.on("ended", function () {
        console.log("video ended");
        // $.magnificPopup.close(); // Close the popup
        $(document).find('.mfp-close').click(); // Si
      });

      player.on("play", function () {
        console.log("video playing");
        creditsContainer.css("opacity", "0.0");
        closeButton.css("opacity", "0.2");
        player.setVolume(1);
      });

      player.ready().then(function () {
        adjustPlayerSize();
      });

      player.on("pause", function () {
        closeButton.show();
        closeButton.css("opacity", "1");
        creditsContainer.show();
        creditsContainer.css("opacity", "1");
      });

      $(window).resize(function () {
        adjustPlayerSize();
      });

      function adjustPlayerSize() {
        let width = player.element.clientWidth;
        let height = Math.round(width * 0.56);
        player.element.style.height = (height + "px").toString();
      }
    },
    beforeClose: function () {
      $("#iframe-video-background.mfp-if-bg").css("visibility", "hidden");
    }
  },
  mainClass: "mfp-iframe-scaler",
  enableEscapeKey: true,
  showCloseBtn: false,
  closeOnBgClick: false,
  fixedContentPos: true
};

Portfolio.propTypes = {
  parseProjectTitle: PropTypes.func.isRequired,
  parseProjectDescription: PropTypes.func.isRequired
};
