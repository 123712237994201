import React, { useContext } from "react";
import { ProjectContext } from "../context/ProjectsContext";

const Footer = () => {
  const { projects } = useContext(ProjectContext);
  return (
    <div className="call-to-action bg-light">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="section-heading">Let's Get In Touch!</h2>
            <hr />
            <p>
              Ready to start your next project with us? That's great! Give us a
              call or send us an email and we will get back to you as soon as
              possible!
            </p>
          </div>
        </div>
       
        <div id="contact"  className="row element">
          {projects && projects.contactNumber && (
            <div className="col-lg-4 ml-auto text-center">
              <i className="fa fa-phone fa-3x sr-contact" />
              <p>{projects.contactNumber}</p>
            </div>
          )}
          {projects && projects.contactEmail && (
            <div className="col-lg-4 mr-auto text-center">
              <i className="fa fa-envelope-o fa-3x sr-contact" />
              <p>
                <a href={`mailto:${projects.contactEmail}`}>
                  {projects.contactEmail}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
      {projects && projects.title && (
        <div className="container text-center">
          <span>
            {"\u00A9"} 2024 {projects.title}
          </span>
        </div>
      )}
    </div>
  );
};

export default Footer;
