import React, { useEffect, useContext, useState } from "react";
import styled, { keyframes } from 'styled-components';
import { fadeInRight, rubberBand } from 'react-animations/lib/';
import { ProjectContext } from "../context/ProjectsContext";
import * as Scroll from 'react-scroll';
import backgroundVideoList from '../data/backgroundVideos';
import arrowLeft from '../assets/arrow-left.png';
import arrowRight from '../assets/arrow-right.png';

const MonsterHeader = () => {
  const { projects, videoBackgroundId, setVideoBackgroundId } = useContext(ProjectContext);
  const [featuredProjectText, setFeaturedProjectText] = useState([]);
  let Link = Scroll.Link;
 

  const AnimatedLogo = styled.div`
  animation: 0.5s ${keyframes(rubberBand)};
  color:white;
`;

  const AnimatedVideoDescription = styled.div`
  animation: 0.4s ${keyframes(fadeInRight)} forwards;
  animation-delay:0.5s;
  color:white;
  font-size: calc(1.5vh + 1.1vw);;
  opacity:1;
}`;

 

  const parseProjectDescription = (projects, id) => {
    let credits = projects.projects.items.filter((item) => {
      return item.vimeoId === id;
    })
    const c = credits[0].description.split("\n");
    setFeaturedProjectText(c);
  };

  const getDescription = (projects, id) => {
    let credits = projects.projects.items.filter((item) => {
      return item.vimeoId === id;
    })
    const name = credits[0].name.split('|')[0];
    setFeaturedProjectText(name);
  };


  const next = () => {
    const i = backgroundVideoList.findIndex(object => {
      return object.id === videoBackgroundId;
    });

    const target = (i + 1 > backgroundVideoList.length - 1) ? 0 : i + 1;
    setVideoBackgroundId(backgroundVideoList[target].id);
  };

  const previous = () => {
    const i = backgroundVideoList.findIndex(object => {
      return object.id === videoBackgroundId;
    });

    const target = (i - 1 < 0) ? backgroundVideoList.length - 1 : i - 1;
    setVideoBackgroundId(backgroundVideoList[target].id);
  };

  useEffect(() => {
    if (videoBackgroundId && projects && projects.projects) {
      parseProjectDescription(projects, videoBackgroundId);
      getDescription(projects, videoBackgroundId)
    }
  }, [videoBackgroundId, projects]);

  return (
    <div>
      <div className="row p-4 mt-5">
        <div className="w-10 col-1 my-auto">
          <div className="h-100 next-prev" onClick={(e) => { e.stopPropagation(); previous() }} >  <img src={arrowLeft} alt="arrow prev" /> </div>
        </div>
        <div className="col-10">
          <header className="masthead" role="presentation">
            <div className="loading-fadein header-content">
              <div className="header-content-inner">
                {projects && projects.file.key ? (
                  <div style={{ "marginTop": "250px" }}>
                    <div> <AnimatedLogo><img alt="monster logo" src={projects.file.url} /></AnimatedLogo></div>
                    <p />
                    <div >
                      <AnimatedVideoDescription style={{ "opacity": "0" }}>
                        <div>
                          {featuredProjectText}
                        </div>
                      </AnimatedVideoDescription>
                    </div>

                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </header>
        </div>

        <div className="col-1 text-right my-auto next-prev">
          <div onClick={(e) => { e.stopPropagation(); next() }}> <img src={arrowRight} alt="arrow next" /></div>
        </div>
      </div>
      <div className="text-center mt-2">
        <Link className="btn btn-primary btn-xl" style={{ "cursor": "pointer", "color": "white", }} to="portfolio-monster" onClick={(e) => { e.stopPropagation() }} spy={true} smooth={true} duration={500}>
          VIEW ALL PROJECTS
        </Link>
      </div>
    </div>
  );
};

export default MonsterHeader;
