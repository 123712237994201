import React, { useContext } from "react";
import { ProjectContext } from "../context/ProjectsContext";
import { isMobile } from "react-device-detect";
import Navbar from "./Navbar";
import BackgroundVideo from "./BackgroundVideo";
import MonsterHeader from "./MonsterHeader";
import styled from "styled-components";
import $ from "jquery";

const Top = styled.div`
height: 1080px;
`;

function ContainerTop() {
  const { videoBackgroundId } = useContext(ProjectContext);
  return (
    <>
      {isMobile === false && videoBackgroundId &&(
        <Top onClick={() => { $('#'+videoBackgroundId+' .portfolio-box').trigger('click'); }}>
          <Navbar />
          <BackgroundVideo
          />
          <MonsterHeader />
        </Top>
      )}
      {
        isMobile === true && <Navbar />
      }
    </>
  );
}

export default ContainerTop;
