import React, { useState, createContext } from 'react'
import backgroundVideoList from '../data/backgroundVideos';
export const ProjectContext = createContext();

const ProjectContextProvider = (props) => {
    const [projects, setProjects] = useState();
    const [projectPreviewsLoadCount, setProjectPreviewsLoadCount] = useState(0)
    const id =  backgroundVideoList[0].id;
    const [videoBackgroundId, setVideoBackgroundId] = useState(id);
    return (
      <ProjectContext.Provider value={{projects, setProjects, videoBackgroundId, setVideoBackgroundId, projectPreviewsLoadCount,setProjectPreviewsLoadCount}}>
        {props.children}
      </ProjectContext.Provider>
    );
  };

  export default ProjectContextProvider;