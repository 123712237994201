import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ProjectContext } from "../context/ProjectsContext";
 
const Video = ({
  src, 
  imageURL ,
  projectsTotal,
  id
}) => {;
  const {projectPreviewsLoadCount, setProjectPreviewsLoadCount } = useContext(ProjectContext);
  return(
    <video
    muted
    loop
    autoPlay
    width="100%"
    height="auto"
    playsInline
    //TODO: this needs to be handles gracefully!
    onError ={() => {
      document.getElementById(id).style.height = '400px';
      // if(projectsTotal === projectPreviewsLoadCount-4){
        if(projectPreviewsLoadCount === 10){
        const elem = document.getElementsByClassName('hide-and-go-load')[0];
        elem.style.visibility = 'hidden';
        const body = document.getElementsByTagName("body");
        body[0].style.overflow = "visible";
      }else{
        setProjectPreviewsLoadCount(projectPreviewsLoadCount+1)
      }
    }}
    onCanPlay={() => {
      if(projectPreviewsLoadCount === 10){
      // if(projectsTotal === projectPreviewsLoadCount){
        const elem = document.getElementsByClassName('hide-and-go-load')[0];
        elem.style.visibility = 'hidden';
        const body = document.getElementsByTagName("body");
        body[0].style.overflow = "visible";
        console.log("Please select")
      }else{
        setProjectPreviewsLoadCount(projectPreviewsLoadCount+1)
      }
     
    }
    }
    id={id}
    className="img-fluid" alt="thumbnail"
    style={{ background: `url(${imageURL.imageUrl}),#000000` }}
  >
    <source src={src} type="video/mp4" />
  </video>
  )
}
 
Video.propTypes = {
  src: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool
}

export default Video