/* eslint-disable no-useless-constructor */
import React, { useContext, useEffect, useState } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import backgroundVideoList from '../data/backgroundVideos';
import "react-html5video/dist/styles.css";
import { ProjectContext } from "../context/ProjectsContext";

const BackgroundVideo = () => {

  const { setVideoBackgroundId, videoBackgroundId } = useContext(ProjectContext);
  const [backgroundURL, setBackgroundUrl] = useState();
  const S3_VIDEO_BUCKET = 'https://s3.eu-west-1.amazonaws.com/monstermusic.tv/video/720/';
  let videoPlayer;
  
  let setVideoPlayerRef = (element) => {
      videoPlayer = element;
  };

  const next = () => {
    const i = backgroundVideoList.findIndex(object => {
      return object.id === videoBackgroundId;
    });

    const target = (i + 1 > backgroundVideoList.length - 1) ? 0 : i + 1;
    setVideoBackgroundId(backgroundVideoList[target].id);
  };

  useEffect(() => {
    if (videoBackgroundId) {
      const videoItem = backgroundVideoList.filter((item) => {
        return item.id === videoBackgroundId;
      })
      const name = videoItem[0].name;
      setBackgroundUrl(S3_VIDEO_BUCKET + name);
      if(backgroundURL){
        videoPlayer.videoEl.src = backgroundURL;
      } 
     
    }
  }, [videoBackgroundId,backgroundURL,videoPlayer]);

  return (
    <>
      {backgroundURL && (
        <Video
          id="video-background"
          autoPlay={true}
          muted
          onEnded={() => {
            setBackgroundUrl(null);
            next();
          }}
          ref={setVideoPlayerRef}
        >
          <source src={backgroundURL} type="video/mp4" />
        </Video>

      )}
    </>
  );

}

export default BackgroundVideo;
