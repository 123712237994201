import React, { useContext } from "react";
import { ProjectContext } from "../context/ProjectsContext";
import * as Scroll from 'react-scroll';
import {  animateScroll as scroll } from 'react-scroll'

const Navbar = () => {

  let Link  = Scroll.Link;
  const { projects } = useContext(ProjectContext);

 const  scrollToTop = (e) =>{
    e.stopPropagation();
    scroll.scrollToTop();
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top"
      id="mainNav"
    >
      <div className="container-fluid">
        {projects && projects.title && (
          <a className="navbar-brand" onClick={scrollToTop} href="#page-top">
            {projects.title}
          </a>
        )}

        <ul className="loading-fadein navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link nav-link-scroller" to="about" spy={true} smooth={true} duration={500}>
          About
        </Link>
          </li>
          <li className="nav-item">
          <Link className="nav-link nav-link-scroller" to="contact" spy={true} smooth={true} duration={500}>
          Contact
        </Link>
          </li>

        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
