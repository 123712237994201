import React from "react";
import ProjectContextProvider from "./context/ProjectsContext";
import { PortfolioContainer } from "./containers/PortfolioContainer";
import ContainerTop from "./components/ContainerTop";
import About from "./components/About";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Container = styled.div`
`;

function App() {

  if (isMobile) {
    const elem = document.getElementsByTagName("body");
    elem[0].style.overflow = "hidden";
  }

  return (
    <Router>
      <ProjectContextProvider>
        <Container className="container-fluid p-0">
          <ContainerTop />
          <div id={"portfolio-monster"}>
            <p >&nbsp;</p>
          </div>
          <Route path="/:id?" component={PortfolioContainer} />

          <div id={"about"}>
            <About />
          </div>


          <Footer />

        </Container>

      </ProjectContextProvider>
      {isMobile && (
        <div className="hide-and-go-load">
          <div className="spinner-grow text-light" role="status">
          </div><br/><br/>
          <span className="loader-text">Loading...</span>
        </div>
      )}
    </Router>
  );
}

export default App;
